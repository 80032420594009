/* eslint-disable no-unused-vars */
import {
  CONTRACT_ERC20,
  ADDRESS_CONTRACT_NFTPresale,
  CONTRACT_NFTPresale,
  CONTRACT_NFTSale,
  GAS_LIMIT,
  CONTRACT_Dividends,
  CONTRACT_BalanceContract,

  NFT1,
  CONTRACT_NFT1,

  NFT3,
  CONTRACT_NFT3,

  NFT4,
  CONTRACT_NFT4,

  NFT5,
  CONTRACT_NFT5,

  CONTRACT_Сapcha,
  CONTRACT_CAPTCHA_balance
} from './variables'

import { ethers } from 'iceconnect'

export class ContractERC20 {
  buy (web3Provider, amount) {
    CONTRACT_ERC20.connect(web3Provider.getUncheckedSigner())
            .contribute({
              gasLimit: ethers.utils.hexlify(GAS_LIMIT),
              value: ethers.utils.parseEther(String(amount)).toHexString()
            })
  }
}

export class ContractNFTPresale {
  mint (web3Provider, price) {
    CONTRACT_NFTPresale.connect(web3Provider.getUncheckedSigner())
            .purchase({
              gasLimit: ethers.utils.hexlify(GAS_LIMIT),
              value: ethers.utils.parseEther(String(price)).toHexString()
            })
  }

  price () {
    return CONTRACT_NFTPresale.salePrice()
          .then(price => ethers.utils.formatEther(price))
  }
}

export class ContractNFTSale {
  balance () {
    return CONTRACT_NFTSale.balanceOf(ADDRESS_CONTRACT_NFTPresale)
  }
}

export class ContractDividends {
  claim (web3Provider) {
    CONTRACT_Dividends.connect(web3Provider.getUncheckedSigner())
          .claim({ gasLimit: ethers.utils.hexlify(GAS_LIMIT)})
  }
}

export class ContractNFTInvestors {
  balance (address) {
    return CONTRACT_BalanceContract.balanceOf(address)
  }

  buy (address, web3Provider, price) {
    if(address === NFT1) {
      CONTRACT_NFT1.connect(web3Provider.getUncheckedSigner())
        .purchase({
          gasLimit: ethers.utils.hexlify(GAS_LIMIT),
          value: ethers.utils.parseEther(String(price)).toHexString()
        })  
    }
    if(address === NFT3) {
      CONTRACT_NFT3.connect(web3Provider.getUncheckedSigner())
      .purchase({
        gasLimit: ethers.utils.hexlify(GAS_LIMIT),
        value: ethers.utils.parseEther(String(price)).toHexString()
      })  
    }
    if(address === NFT4) {
      CONTRACT_NFT4.connect(web3Provider.getUncheckedSigner())
      .purchase({
        gasLimit: ethers.utils.hexlify(GAS_LIMIT),
        value: ethers.utils.parseEther(String(price)).toHexString()
      })  
    }
    if(address === NFT5) {
      CONTRACT_NFT5.connect(web3Provider.getUncheckedSigner())
      .purchase({
        gasLimit: ethers.utils.hexlify(GAS_LIMIT),
        value: ethers.utils.parseEther(String(price)).toHexString()
      })  
    }
  }

  price (nftContract) {
    if (nftContract) {
      return nftContract.salePrice()
          .then(price => ethers.utils.formatEther(price))
    }
  }
}

export class ContractCapcha {
  buy (web3Provider, price, signature) {
    CONTRACT_Сapcha.connect(web3Provider.getUncheckedSigner())
            .buy(signature, {
              gasLimit: ethers.utils.hexlify(GAS_LIMIT),
              value: ethers.utils.parseEther(String(price)).toHexString()
            })
  }

  buyWL (web3Provider, price) {
    CONTRACT_Сapcha.connect(web3Provider.getUncheckedSigner())
                          .buyWl({
                            gasLimit: ethers.utils.hexlify(GAS_LIMIT),
                            value: ethers.utils.parseEther(String(price)).toHexString()
                          })
  }

  whitelist (address) {
    return CONTRACT_Сapcha.whitelist(address)
  }

  balance (address) {
    return CONTRACT_CAPTCHA_balance.balanceOf(address)
            .then(balance => balance.toString())
  }

  price () {
    return CONTRACT_Сapcha.price()
            .then(price => ethers.utils.formatEther(price))
  }
}