import { IceWallet } from './wallet'
import { ContractERC20, ContractNFTPresale, ContractNFTSale, ContractDividends, ContractNFTInvestors, ContractCapcha } from './contract'
import { WalletOwner } from './wallletOwner'
import { Web3 } from './web3'

const clases = {
  contractERC20: new ContractERC20(),
  ContractNFTPresale: new ContractNFTPresale(),
  contractNFTSale: new ContractNFTSale(),
  wallet: new IceWallet(),
  walletOwner: new WalletOwner(),
  contractDividends: new ContractDividends(),
  contractNFTInvestors: new ContractNFTInvestors(),
  contractCapcha: new ContractCapcha()
}

const web3 = new Web3(clases)

export {
  web3
}