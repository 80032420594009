export class Web3 {
  constructor ({ wallet, contractERC20, ContractNFTPresale, contractNFTSale, walletOwner, contractDividends, contractNFTInvestors, contractCapcha }) {
    this.contractERC20 = contractERC20
    this.contractNFTPresale = ContractNFTPresale
    this.contractNFTSale = contractNFTSale
    this.wallet = wallet
    this.walletOwner = walletOwner
    this.contractDividends = contractDividends
    this.contractNFTInvestors = contractNFTInvestors,
    this.contractCapcha = contractCapcha
  }
}